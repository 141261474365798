(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

export var bouncePopup = function bouncePopup() {
  (function (a) {
    var b = a.createElement('script');
    b.src = "".concat(a.location.protocol, "//tag.bounceexchange.com/4051/i.js");
    b.async = !0;
    a.getElementsByTagName('head')[0].appendChild(b);
  })(document);
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(bouncePopup, "bouncePopup", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/variants/products/js/bounce-popup.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();