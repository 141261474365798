(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

export var anatomyCopy = {
  'amalfi-flat-black-classic': {
    title: 'The Features of the Amalfi',
    suitImage: '/assets/images/landing-pages/products-1/anatomy-of-amalfi.png',
    suitImageWebp: '/assets/images/landing-pages/products-1/anatomy-of-amalfi.webp'
  },
  'tulum-flat-black-classic': {
    title: 'The Features of the Tulum',
    suitImage: '/assets/images/landing-pages/products-1/anatomy-of-tulum.png',
    suitImageWebp: '/assets/images/landing-pages/products-1/anatomy-of-tulum.webp'
  },
  'malibu-flat-black-classic': {
    title: 'The Features of the Malibu',
    suitImage: '/assets/images/landing-pages/products-1/anatomy-of-malibu.png',
    suitImageWebp: '/assets/images/landing-pages/products-1/anatomy-of-malibu.webp'
  },
  'mykonos-flat-black-classic': {
    title: 'The Features of the Mykonos',
    suitImage: '/assets/images/landing-pages/products-1/anatomy-of-mykoons.png',
    suitImageWebp: '/assets/images/landing-pages/products-1/anatomy-of-mykoons.webp'
  },
  'jetties-flat-black-classic': {
    title: 'The Features of the Jetties',
    suitImage: '/assets/images/landing-pages/products-1/anatomy-of-jetties.png',
    suitImageWebp: '/assets/images/landing-pages/products-1/anatomy-of-jetties.webp'
  }
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(anatomyCopy, "anatomyCopy", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/variants/products/js/anatomy-section-constants.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();