(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

export var yotpoWidget = function yotpoWidget() {
  (function e() {
    var elm = document.createElement('script');
    elm.type = 'text/javascript', elm.async = true, elm.defer = 'defer', elm.src = '//staticw2.yotpo.com/si5GHYtW6qDdduxrgPOHFLkERGXpkBY88nvRJe5c/widget.js';
    var t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(elm, t);
  })();
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(yotpoWidget, "yotpoWidget", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/js/yotpo-widget.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();