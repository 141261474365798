(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

// Get Date , Month & Year
export var todaysDate = new Date();
export var startDate = new Date('July 29 2021 06:30:00 UTC');
export var endDate = new Date('July 30 2021 14:00:00 UTC');
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(todaysDate, "todaysDate", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/date/index.js");
  reactHotLoader.register(startDate, "startDate", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/date/index.js");
  reactHotLoader.register(endDate, "endDate", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/date/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();