import _typeof from "@babel/runtime/helpers/typeof";

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import { basicDataLoad } from "../core/utils/firebase"; // you may also like: add static products to show in cart here

var res = {
  'The Swim Club Baseball Hat - White - One Size': {
    title: 'The Swim Club Baseball Hat - White - One Size',
    fullTitle: 'The Swim Club Baseball Hat - White - One Size',
    price: {
      cents: 1800,
      label: "$18"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '36477029056584',
    quantity: 0,
    availableForSale: true,
    color: null,
    size: 'One Size',
    href: 'https://andieswim.com/products/the-swim-club-baseball-hat',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/R21_Merch_Cap_White_2.jpg?v=1605639059'
  },
  'Molly Suds Swim Detergent - One Size': {
    title: 'Molly Suds Swim Detergent - One Size',
    fullTitle: 'Molly Suds Swim Detergent - One Size',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '31895177527368',
    quantity: 0,
    availableForSale: true,
    color: null,
    size: 'One Size',
    href: 'https://andieswim.com/products/molly-suds-swim-detergent',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/SS20_MollySuds_SWIM.jpg?v=1590112981'
  },
  'The Swim Club Tote Bag - Navy - One Size': {
    title: 'The Swim Club Tote Bag - Navy - One Size',
    fullTitle: 'The Swim Club Tote Bag - Navy - One Size',
    price: {
      cents: 1800,
      label: "$18"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '36477028958280',
    quantity: 0,
    availableForSale: true,
    color: 'Navy',
    size: 'One Size',
    showSize: true,
    href: 'https://andieswim.com/products/the-swim-club-tote-bag?variant=36477028958280',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/RE21_Merch_Tote_Blue_1.jpg?v=1605638027'
  },
  'Supergoop! PLAY Everyday Lotion SPF 30 - One Size': {
    title: 'Supergoop! PLAY Everyday Lotion SPF 30',
    fullTitle: 'Supergoop! PLAY Everyday Lotion SPF 30 - One Size',
    price: {
      cents: 3200,
      label: "$32"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '32150779560008',
    quantity: 0,
    availableForSale: true,
    color: null,
    size: 'One Size',
    showSize: true,
    href: 'https://andieswim.com/products/supergoop-everyday-lotion-30?variant=32150779560008',
    cartImage: // eslint-disable-next-line max-len
    'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/2051_Image2_1024x1024_9bf188e3-883d-4c16-bc7a-6a04b5569d4a.jpg?v=1621627512'
  },
  'Supergoop! Unseen Sunscreen SPF 40 - One Size': {
    title: 'Supergoop! Unseen Sunscreen SPF 40',
    fullTitle: 'Supergoop! Unseen Sunscreen SPF 40 - One Size',
    price: {
      cents: 3400,
      label: "$34"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '36516516823112',
    quantity: 0,
    availableForSale: true,
    color: null,
    size: 'One Size',
    showSize: true,
    href: 'https://andieswim.com/products/supergoop-unseen-sunscreen-spf-40?variant=36516516823112',
    cartImage: // eslint-disable-next-line max-len
    'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/UNSEEN_PDP_TEST_PJW_10_07_2020.jpg?v=1611080249'
  },
  'Vacation® Chardonnay Oil SPF 30 - One Size': {
    title: 'Vacation® Chardonnay Oil SPF 30',
    fullTitle: 'Vacation® Chardonnay Oil SPF 30 - One Size',
    price: {
      cents: 2200,
      label: "$22"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39424513015880',
    quantity: 0,
    availableForSale: true,
    color: 'White',
    size: 'One Size',
    showSize: true,
    href: 'https://andieswim.com/products/chardonnay-oil-spf-30',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/oil-clean_960x.jpg?v=1644601761'
  },
  'Vacation® Super Spritzer - One Size': {
    title: 'Vacation® Super Spritzer',
    fullTitle: 'Vacation® Super Spritzer - One Size',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39425935212616',
    quantity: 0,
    availableForSale: true,
    color: 'White',
    size: 'One Size',
    showSize: true,
    href: 'https://andieswim.com/products/super-spritzer',
    cartImage: // eslint-disable-next-line max-len
    '//cdn.shopify.com/s/files/1/0024/2289/8758/products/spritz-clean_960x.jpg?v=1644600930'
  },
  'The Plunge Bralette - Black - M': {
    title: 'The Plunge Bralette - Rayon',
    fullTitle: 'The Plunge Bralette - Black - M',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39425935212616',
    product_id: 'gid://shopify/Product/6542342225992',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-plunge-bralette-rayon-black',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/Plunge-Bralette_Intimates_Black_1_960x.jpg?v=1629491114'
  },
  'The Plunge Bralette - Black - S': {
    title: 'The Plunge Bralette - Rayon',
    fullTitle: 'The Plunge Bralette - Black - S',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819290696',
    product_id: 'gid://shopify/Product/6542342225992',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-plunge-bralette-rayon-black',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/Plunge-Bralette_Intimates_Black_1_960x.jpg?v=1629491114'
  },
  'The Plunge Bralette - Black - XS': {
    title: 'The Plunge Bralette - Rayon',
    fullTitle: 'The Plunge Bralette - Black - XS',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819257928',
    product_id: 'gid://shopify/Product/6542342225992',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-plunge-bralette-rayon-black',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/Plunge-Bralette_Intimates_Black_1_960x.jpg?v=1629491114'
  },
  'The Plunge Bralette - Black - L': {
    title: 'The Plunge Bralette - Rayon',
    fullTitle: 'The Plunge Bralette - Black - L',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819356232',
    product_id: 'gid://shopify/Product/6542342225992',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-plunge-bralette-rayon-black',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/Plunge-Bralette_Intimates_Black_1_960x.jpg?v=1629491114'
  },
  'The Plunge Bralette - Black - XL': {
    title: 'The Plunge Bralette - Rayon',
    fullTitle: 'The Plunge Bralette - Black - XL',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819389000',
    product_id: 'gid://shopify/Product/6542342225992',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-plunge-bralette-rayon-black',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/Plunge-Bralette_Intimates_Black_1_960x.jpg?v=1629491114'
  },
  'The Plunge Bralette - Black - XXL': {
    title: 'The Plunge Bralette - Rayon',
    fullTitle: 'The Plunge Bralette - Black - XXL',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819421768',
    product_id: 'gid://shopify/Product/6542342225992',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-plunge-bralette-rayon-black',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/Plunge-Bralette_Intimates_Black_1_960x.jpg?v=1629491114'
  },
  'The Plunge Bralette - Black - XXXL': {
    title: 'The Plunge Bralette - Rayon',
    fullTitle: 'The Plunge Bralette - Black - XXXL',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819454536',
    product_id: 'gid://shopify/Product/6542342225992',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-plunge-bralette-rayon-black',
    cartImage: '//cdn.shopify.com/s/files/1/0024/2289/8758/products/Plunge-Bralette_Intimates_Black_1_960x.jpg?v=1629491114'
  },
  'The Brief - Black - XXXL': {
    title: 'The Brief - Rayon',
    fullTitle: 'The Brief - Black - XXXL',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819225160',
    product_id: 'gid://shopify/Product/6542342193224',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-brief-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Brief_Underwear_Black_1.jpg?v=1626725862'
  },
  'The Brief - Black - XXL': {
    title: 'The Brief - Rayon',
    fullTitle: 'The Brief - Black - XXL',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819192392',
    product_id: 'gid://shopify/Product/6542342193224',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-brief-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Brief_Underwear_Black_1.jpg?v=1626725862'
  },
  'The Brief - Black - XL': {
    title: 'The Brief - Rayon',
    fullTitle: 'The Brief - Black - XL',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819159624',
    product_id: 'gid://shopify/Product/6542342193224',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-brief-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Brief_Underwear_Black_1.jpg?v=1626725862'
  },
  'The Brief - Black - L': {
    title: 'The Brief - Rayon',
    fullTitle: 'The Brief - Black - L',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819126856',
    product_id: 'gid://shopify/Product/6542342193224',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-brief-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Brief_Underwear_Black_1.jpg?v=1626725862'
  },
  'The Brief - Black - M': {
    title: 'The Brief - Rayon',
    fullTitle: 'The Brief - Black - M',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819094088',
    product_id: 'gid://shopify/Product/6542342193224',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-brief-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Brief_Underwear_Black_1.jpg?v=1626725862'
  },
  'The Brief - Black - S': {
    title: 'The Brief - Rayon',
    fullTitle: 'The Brief - Black - S',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819061320',
    product_id: 'gid://shopify/Product/6542342193224',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-brief-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Brief_Underwear_Black_1.jpg?v=1626725862'
  },
  'The Brief - Black - XS': {
    title: 'The Brief - Rayon',
    fullTitle: 'The Brief - Black - XS',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267819028552',
    product_id: 'gid://shopify/Product/6542342193224',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-brief-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Brief_Underwear_Black_1.jpg?v=1626725862'
  },
  'The Thong — rayon - S': {
    title: 'The Thong - Rayon',
    fullTitle: 'The Thong — rayon - S',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267821846600',
    product_id: 'gid://shopify/Product/6542342619208',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-thong-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Thong_Underwear_Intimates_Black_1.jpg?v=1629947956'
  },
  'The Thong — rayon - XS': {
    title: 'The Thong - Rayon',
    fullTitle: 'The Thong — rayon - XS',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267821813832',
    product_id: 'gid://shopify/Product/6542342619208',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-thong-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Thong_Underwear_Intimates_Black_1.jpg?v=1629947956'
  },
  'The Thong — rayon - M': {
    title: 'The Thong - Rayon',
    fullTitle: 'The Thong — rayon - M',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267821879368',
    product_id: 'gid://shopify/Product/6542342619208',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-thong-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Thong_Underwear_Intimates_Black_1.jpg?v=1629947956'
  },
  'The Thong — rayon - L': {
    title: 'The Thong - Rayon',
    fullTitle: 'The Thong — rayon - L',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267821912136',
    product_id: 'gid://shopify/Product/6542342619208',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-thong-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Thong_Underwear_Intimates_Black_1.jpg?v=1629947956'
  },
  'The Thong — rayon - XL': {
    title: 'The Thong - Rayon',
    fullTitle: 'The Thong — rayon - XL',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267821944904',
    product_id: 'gid://shopify/Product/6542342619208',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-thong-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Thong_Underwear_Intimates_Black_1.jpg?v=1629947956'
  },
  'The Thong — rayon - XXL': {
    title: 'The Thong - Rayon',
    fullTitle: 'The Thong — rayon - XXL',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267821977672',
    product_id: 'gid://shopify/Product/6542342619208',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-thong-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Thong_Underwear_Intimates_Black_1.jpg?v=1629947956'
  },
  'The Thong — rayon - XXXL': {
    title: 'The Thong - Rayon',
    fullTitle: 'The Thong — rayon - XXXL',
    price: {
      cents: 1200,
      label: "$12"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267822010440',
    product_id: 'gid://shopify/Product/6542342619208',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-thong-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Thong_Underwear_Intimates_Black_1.jpg?v=1629947956'
  },
  'The Tank - Bralette - Black - S': {
    title: 'The Tank Bralette - Rayon',
    fullTitle: 'The Tank - Bralette - Black - S',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267820240968',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-tank-bralette-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Tank-Bralette_Intimates_Black_1.jpg?v=1629491708'
  },
  'The Tank - Bralette - Black - M': {
    title: 'The Tank Bralette - Rayon',
    fullTitle: 'The Tank - Bralette - Black - M',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267820273736',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-tank-bralette-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Tank-Bralette_Intimates_Black_1.jpg?v=1629491708'
  },
  'The Tank - Bralette - Black - L': {
    title: 'The Tank Bralette - Rayon',
    fullTitle: 'The Tank - Bralette - Black - L',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267820306504',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-tank-bralette-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Tank-Bralette_Intimates_Black_1.jpg?v=1629491708'
  },
  'The Tank - Bralette - Black - XL': {
    title: 'The Tank Bralette - Rayon',
    fullTitle: 'The Tank - Bralette - Black - XL',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267820339272',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-tank-bralette-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Tank-Bralette_Intimates_Black_1.jpg?v=1629491708'
  },
  'The Tank - Bralette - Black - XXXL': {
    title: 'The Tank Bralette - Rayon',
    fullTitle: 'The Tank - Bralette - Black - XXXL',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267820404808',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-tank-bralette-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Tank-Bralette_Intimates_Black_1.jpg?v=1629491708'
  },
  'The Tank - Bralette - Black - XXL': {
    title: 'The Tank Bralette - Rayon',
    fullTitle: 'The Tank - Bralette - Black - XXL',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39957412118575',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-tank-bralette-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Tank-Bralette_Intimates_Black_1.jpg?v=1629491708'
  },
  'The Tank - Bralette - Black - XS': {
    title: 'The Tank Bralette - Rayon',
    fullTitle: 'The Tank - Bralette - Black - XS',
    price: {
      cents: 2800,
      label: "$28"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39267820208200',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Black',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-tank-bralette-rayon-black',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/Tank-Bralette_Intimates_Black_1.jpg?v=1629491708'
  },
  'The Flores Sarong - Linen - Coconut - S/M': {
    title: 'The Flores Sarong - Linen - Coconut',
    fullTitle: 'The Flores Sarong - Linen - Coconut - S/M',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485425975368',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S/M',
    showSize: true,
    href: 'https://andieswim.com/products/the-flores-sarong-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/linen-pdp.jpg?v=1655308088'
  },
  'The Flores Sarong - Linen - Coconut - L/XL': {
    title: 'The Flores Sarong - Linen - Coconut',
    fullTitle: 'The Flores Sarong - Linen - Coconut - L/XL',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485426008136',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L/XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-flores-sarong-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/linen-pdp.jpg?v=1655308088'
  },
  'The Flores Sarong - Linen - Coconut - XXL/XXXL': {
    title: 'The Flores Sarong - Linen - Coconut',
    fullTitle: 'The Flores Sarong - Linen - Coconut - XXL/XXXL',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485426040904',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXL/XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-flores-sarong-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/linen-pdp.jpg?v=1655308088'
  },
  'The Armona Dress - Linen - Coconut - XS': {
    title: 'The Armona Dress - Linen - Coconut',
    fullTitle: 'The Armona Dress - Linen - Coconut - XS',
    price: {
      cents: 16500,
      label: "$165"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478950395976',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-armona-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-maxi-1.jpg?v=1655158401'
  },
  'The Armona Dress - Linen - Coconut - S': {
    title: 'The Armona Dress - Linen - Coconut',
    fullTitle: 'The Armona Dress - Linen - Coconut - S',
    price: {
      cents: 16500,
      label: "$165"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478950428744',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-armona-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-maxi-1.jpg?v=1655158401'
  },
  'The Armona Dress - Linen - Coconut - M': {
    title: 'The Armona Dress - Linen - Coconut',
    fullTitle: 'The Armona Dress - Linen - Coconut - M',
    price: {
      cents: 16500,
      label: "$165"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478950461512',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-armona-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-maxi-1.jpg?v=1655158401'
  },
  'The Armona Dress - Linen - Coconut - L': {
    title: 'The Armona Dress - Linen - Coconut',
    fullTitle: 'The Armona Dress - Linen - Coconut - L',
    price: {
      cents: 16500,
      label: "$165"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478950494280',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-armona-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-maxi-1.jpg?v=1655158401'
  },
  'The Armona Dress - Linen - Coconut - XL': {
    title: 'The Armona Dress - Linen - Coconut',
    fullTitle: 'The Armona Dress - Linen - Coconut - XL',
    price: {
      cents: 16500,
      label: "$165"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478950527048',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-armona-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-maxi-1.jpg?v=1655158401'
  },
  'The Armona Dress - Linen - Coconut - XXL': {
    title: 'The Armona Dress - Linen - Coconut',
    fullTitle: 'The Armona Dress - Linen - Coconut - XXL',
    price: {
      cents: 16500,
      label: "$165"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478950559816',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-armona-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-maxi-1.jpg?v=1655158401'
  },
  'The Armona Dress - Linen - Coconut - XXXL': {
    title: 'The Armona Dress - Linen - Coconut',
    fullTitle: 'The Armona Dress - Linen - Coconut - XXXL',
    price: {
      cents: 16500,
      label: "$165"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478950592584',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-armona-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-maxi-1.jpg?v=1655158401'
  },
  'The Cacela Dress - Linen - Coconut - XS': {
    title: 'The Cacela Dress - Linen - Coconut',
    fullTitle: 'The Cacela Dress - Linen - Coconut - XS',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949511240',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-cacela-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/new-coconut-wrap-1.jpg?v=1655157437'
  },
  'The Cacela Dress - Linen - Coconut - S': {
    title: 'The Cacela Dress - Linen - Coconut',
    fullTitle: 'The Cacela Dress - Linen - Coconut - S',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949544008',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-cacela-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/new-coconut-wrap-1.jpg?v=1655157437'
  },
  'The Cacela Dress - Linen - Coconut - M': {
    title: 'The Cacela Dress - Linen - Coconut',
    fullTitle: 'The Cacela Dress - Linen - Coconut - M',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949576776',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-cacela-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/new-coconut-wrap-1.jpg?v=1655157437'
  },
  'The Cacela Dress - Linen - Coconut - L': {
    title: 'The Cacela Dress - Linen - Coconut',
    fullTitle: 'The Cacela Dress - Linen - Coconut - L',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949609544',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-cacela-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/new-coconut-wrap-1.jpg?v=1655157437'
  },
  'The Cacela Dress - Linen - Coconut - XL': {
    title: 'The Cacela Dress - Linen - Coconut',
    fullTitle: 'The Cacela Dress - Linen - Coconut - XL',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949642312',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-cacela-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/new-coconut-wrap-1.jpg?v=1655157437'
  },
  'The Barreta Dress - Linen - Coconut - XS': {
    title: 'The Barreta Dress - Linen - Coconut',
    fullTitle: 'The Barreta Dress - Linen - Coconut - XS',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949511240',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-barreta-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-slip-dress-1.jpg?v=1655157719'
  },
  'The Barreta Dress - Linen - Coconut - S': {
    title: 'The Barreta Dress - Linen - Coconut',
    fullTitle: 'The Barreta Dress - Linen - Coconut - S',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949019720',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-barreta-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-slip-dress-1.jpg?v=1655157719'
  },
  'The Barreta Dress - Linen - Coconut - M': {
    title: 'The Barreta Dress - Linen - Coconut',
    fullTitle: 'The Barreta Dress - Linen - Coconut - M',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949052488',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-barreta-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-slip-dress-1.jpg?v=1655157719'
  },
  'The Barreta Dress - Linen - Coconut - L': {
    title: 'The Barreta Dress - Linen - Coconut',
    fullTitle: 'The Barreta Dress - Linen - Coconut - L',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949085256',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-barreta-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-slip-dress-1.jpg?v=1655157719'
  },
  'The Barreta Dress - Linen - Coconut - XL': {
    title: 'The Barreta Dress - Linen - Coconut',
    fullTitle: 'The Barreta Dress - Linen - Coconut - XL',
    price: {
      cents: 13500,
      label: "$135"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478949118024',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-barreta-dress-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-slip-dress-1.jpg?v=1655157719'
  },
  'The Graciosa Short - Linen - Coconut - XS': {
    title: 'The Graciosa Short - Linen - Coconut',
    fullTitle: 'The Graciosa Short - Linen - Coconut - XS',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485361324104',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-graciosa-short-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-the-short-1_b568e1dc-24ca-4754-a863-cf556efd64e0.jpg?v=1655234693'
  },
  'The Graciosa Short - Linen - Coconut - S': {
    title: 'The Graciosa Short - Linen - Coconut',
    fullTitle: 'The Graciosa Short - Linen - Coconut - S',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485361356872',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-graciosa-short-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-the-short-1_b568e1dc-24ca-4754-a863-cf556efd64e0.jpg?v=1655234693'
  },
  'The Graciosa Short - Linen - Coconut - M': {
    title: 'The Graciosa Short - Linen - Coconut',
    fullTitle: 'The Graciosa Short - Linen - Coconut - M',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485361389640',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-graciosa-short-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-the-short-1_b568e1dc-24ca-4754-a863-cf556efd64e0.jpg?v=1655234693'
  },
  'The Graciosa Short - Linen - Coconut - XL': {
    title: 'The Graciosa Short - Linen - Coconut',
    fullTitle: 'The Graciosa Short - Linen - Coconut - XL',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485361455176',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-graciosa-short-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-the-short-1_b568e1dc-24ca-4754-a863-cf556efd64e0.jpg?v=1655234693'
  },
  'The Graciosa Short - Linen - Coconut - L': {
    title: 'The Graciosa Short - Linen - Coconut',
    fullTitle: 'The Graciosa Short - Linen - Coconut - L',
    price: {
      cents: 7500,
      label: "$75"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485361422408',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-graciosa-short-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-the-short-1_b568e1dc-24ca-4754-a863-cf556efd64e0.jpg?v=1655234693'
  },
  'The Corvo Button Up - Linen - Coconut - XS': {
    title: 'The Corvo Button Up - Linen - Coconut',
    fullTitle: 'The Corvo Button Up - Linen - Coconut - XS',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485320659016',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-corvo-button-up-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/cover-up-pdp.jpg?v=1655237530'
  },
  'The Corvo Button Up - Linen - Coconut - S': {
    title: 'The Corvo Button Up - Linen - Coconut',
    fullTitle: 'The Corvo Button Up - Linen - Coconut - S',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485320691784',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-corvo-button-up-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/cover-up-pdp.jpg?v=1655237530'
  },
  'The Corvo Button Up - Linen - Coconut - M': {
    title: 'The Corvo Button Up - Linen - Coconut',
    fullTitle: 'The Corvo Button Up - Linen - Coconut - M',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485320724552',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-corvo-button-up-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/cover-up-pdp.jpg?v=1655237530'
  },
  'The Corvo Button Up - Linen - Coconut - L': {
    title: 'The Corvo Button Up - Linen - Coconut',
    fullTitle: 'The Corvo Button Up - Linen - Coconut - L',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485320757320',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-corvo-button-up-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/cover-up-pdp.jpg?v=1655237530'
  },
  'The Corvo Button Up - Linen - Coconut - XL': {
    title: 'The Corvo Button Up - Linen - Coconut',
    fullTitle: 'The Corvo Button Up - Linen - Coconut - XL',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485320855624',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-corvo-button-up-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/cover-up-pdp.jpg?v=1655237530'
  },
  'The Corvo Button Up - Linen - Coconut - XXL': {
    title: 'The Corvo Button Up - Linen - Coconut',
    fullTitle: 'The Corvo Button Up - Linen - Coconut - XXL',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485320986696',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-corvo-button-up-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/cover-up-pdp.jpg?v=1655237530'
  },
  'The Corvo Button Up - Linen - Coconut - XXXL': {
    title: 'The Corvo Button Up - Linen - Coconut',
    fullTitle: 'The Corvo Button Up - Linen - Coconut - XXXL',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485320986696',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-corvo-button-up-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/cover-up-pdp.jpg?v=1655237530'
  },
  'The Madeira Pant - Linen - Coconut - XS': {
    title: 'The Madeira Pant - Linen - Coconut',
    fullTitle: 'The Madeira Pant - Linen - Coconut - XS',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485349920840',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XS',
    showSize: true,
    href: 'https://andieswim.com/products/the-madeira-pant-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-pant-1_d939e8d0-7f4e-41e8-8cbc-da80d1187d4e.jpg?v=1655234063'
  },
  'The Madeira Pant - Linen - Coconut - S': {
    title: 'The Madeira Pant - Linen - Coconut',
    fullTitle: 'The Madeira Pant - Linen - Coconut - S',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485349953608',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S',
    showSize: true,
    href: 'https://andieswim.com/products/the-madeira-pant-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-pant-1_d939e8d0-7f4e-41e8-8cbc-da80d1187d4e.jpg?v=1655234063'
  },
  'The Madeira Pant - Linen - Coconut - M': {
    title: 'The Madeira Pant - Linen - Coconut',
    fullTitle: 'The Madeira Pant - Linen - Coconut - M',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485349986376',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'M',
    showSize: true,
    href: 'https://andieswim.com/products/the-madeira-pant-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-pant-1_d939e8d0-7f4e-41e8-8cbc-da80d1187d4e.jpg?v=1655234063'
  },
  'The Madeira Pant - Linen - Coconut - L': {
    title: 'The Madeira Pant - Linen - Coconut',
    fullTitle: 'The Madeira Pant - Linen - Coconut - L',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485350019144',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L',
    showSize: true,
    href: 'https://andieswim.com/products/the-madeira-pant-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-pant-1_d939e8d0-7f4e-41e8-8cbc-da80d1187d4e.jpg?v=1655234063'
  },
  'The Madeira Pant - Linen - Coconut - XL': {
    title: 'The Madeira Pant - Linen - Coconut',
    fullTitle: 'The Madeira Pant - Linen - Coconut - XL',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485350051912',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-madeira-pant-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-pant-1_d939e8d0-7f4e-41e8-8cbc-da80d1187d4e.jpg?v=1655234063'
  },
  'The Madeira Pant - Linen - Coconut - XXL': {
    title: 'The Madeira Pant - Linen - Coconut',
    fullTitle: 'The Madeira Pant - Linen - Coconut - XXL',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485350150216',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-madeira-pant-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-pant-1_d939e8d0-7f4e-41e8-8cbc-da80d1187d4e.jpg?v=1655234063'
  },
  'The Madeira Pant - Linen - Coconut - XXXL': {
    title: 'The Madeira Pant - Linen - Coconut',
    fullTitle: 'The Madeira Pant - Linen - Coconut - XXXL',
    price: {
      cents: 11500,
      label: "$115"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39485350150216',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-madeira-pant-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-pant-1_d939e8d0-7f4e-41e8-8cbc-da80d1187d4e.jpg?v=1655234063'
  },
  'The Tavira Skirt  - Linen - Coconut - S/M': {
    title: 'The Tavira Skirt  - Linen - Coconut',
    fullTitle: 'The Tavira Skirt  - Linen - Coconut - S/M',
    price: {
      cents: 9500,
      label: "$95"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478951379016',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'S/M',
    showSize: true,
    href: 'https://andieswim.com/products/the-tavira-skirt-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-skirt.jpg?v=1655146689'
  },
  'The Tavira Skirt  - Linen - Coconut - L/XL': {
    title: 'The Tavira Skirt  - Linen - Coconut',
    fullTitle: 'The Tavira Skirt  - Linen - Coconut - L/XL',
    price: {
      cents: 9500,
      label: "$95"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478951411784',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'L/XL',
    showSize: true,
    href: 'https://andieswim.com/products/the-tavira-skirt-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-skirt.jpg?v=1655146689'
  },
  'The Tavira Skirt  - Linen - Coconut - XXL/XXXL': {
    title: 'The Tavira Skirt  - Linen - Coconut',
    fullTitle: 'The Tavira Skirt  - Linen - Coconut - XXL/XXXL',
    price: {
      cents: 9500,
      label: "$95"
    },
    compareAt: {
      cents: null,
      label: null
    },
    variant_id: '39478951444552',
    product_id: 'gid://shopify/Product/6542342389832',
    quantity: 0,
    availableForSale: true,
    color: 'Coconut',
    size: 'XXL/XXXL',
    showSize: true,
    href: 'https://andieswim.com/products/the-tavira-skirt-linen-coconut',
    cartImage: 'https://cdn.shopify.com/s/files/1/0024/2289/8758/products/coconut-wrap-skirt.jpg?v=1655146689'
  }
};
var suitStyles = ['flat', 'ribbed', 'eco', 'bloom', 'terra', 'ripple', 'luster', 'ribbed-eco', 'ribbed-terry', 'gingham', 'glossy-eco-nylon', 'rayon', 'wave', 'eco-nylon'];

function structureData(object) {
  if (_typeof(object) === 'object') {
    Object.keys(object).forEach(function (key) {
      var value = object[key];

      if (key === 'variants') {
        Object.keys(value).reduce(function (acc, size) {
          var variant = value[size];
          acc[variant.name] = {
            title: variant.name,
            fullTitle: variant.name,
            price: {
              cents: variant.price,
              label: "$".concat(variant.price / 100)
            },
            compareAt: {
              cents: Number.isNaN(variant.compareAtPrice) ? null : variant.compareAtPrice,
              label: "$".concat(variant.compareAtPrice / 100)
            },
            variant_id: variant.variantId,
            product_id: object.id,
            quantity: 0,
            availableForSale: variant.availableForSale,
            color: object.color,
            size: variant.title,
            href: "https://andieswim.com/products/".concat(object.handle),
            cartImage: object.images[0] && object.images[0].originalSrc
          };
          return acc;
        }, res);
      } else if (value) {
        structureData(value);
      }
    });
  }
}

function structureSwimFitData(object, obj, collection) {
  if (_typeof(object) === 'object') {
    Object.keys(object).forEach(function (key) {
      var value = object[key];

      if (key === 'variants') {
        Object.keys(value).reduce(function (acc, size) {
          var variant = value[size];
          acc[object.title] = {
            masterName: object.title,
            name: collection.replaceAll('-', ' '),
            price: variant.price,
            id: variant.variantId,
            product_id: object.id,
            url: object.onlineStoreUrl,
            featuredImage: object.images[0].originalSrc,
            quiz: true,
            handle: object.handle,
            sizes: object.variants,
            type: object.title.includes('Long Torso') || object.title.includes('Classic') ? 'one piece' : 'top',
            fit: object.title.includes('Long Torso') && 'long torso' || object.title.includes('Classic') && 'classic'
          };
          return acc;
        }, obj);
      } else if (value) {
        structureSwimFitData(value, obj, collection);
      }
    });
  }
}

export var useFirebasePDP = function useFirebasePDP(collection, suit, country) {
  return basicDataLoad(collection, suit, country).then(function (doc) {
    if (doc.exists) {
      console.info('doc exists');

      if (!Object.keys(doc.data()).some(function (r) {
        return suitStyles.includes(r);
      })) {
        window.location.replace('https://andieswim.com/collections/best-sellers');
      }

      structureData(doc.data());
      console.info('data', res);
      var suitData = {
        suitObj: doc.data(),
        suitList: res
      };
      return suitData;
    }

    window.location.replace('https://andieswim.com/collections/best-sellers');
    return console.info('Firestore document not found');
  }).catch(function (error) {
    console.info('firebase error:', error);
    atrack('Error', {
      error_response: error
    });
  });
};
export var useFirebase = function useFirebase(collection, suit, obj, country) {
  return basicDataLoad(collection, suit, country).then(function (doc) {
    if (doc.exists) {
      console.info('doc exists', doc.data());
      structureSwimFitData(doc.data(), obj, collection);
      return obj;
    }

    window.location.replace('https://andieswim.com/collections/best-sellers');
    return console.info('Firestore document not found');
  }).catch(function (error) {
    console.info('firebase error:', error);
    atrack('Error', {
      error_response: error
    });
  });
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(res, "res", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/react-hooks/useFirebasePDP.js");
  reactHotLoader.register(suitStyles, "suitStyles", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/react-hooks/useFirebasePDP.js");
  reactHotLoader.register(structureData, "structureData", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/react-hooks/useFirebasePDP.js");
  reactHotLoader.register(structureSwimFitData, "structureSwimFitData", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/react-hooks/useFirebasePDP.js");
  reactHotLoader.register(useFirebasePDP, "useFirebasePDP", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/react-hooks/useFirebasePDP.js");
  reactHotLoader.register(useFirebase, "useFirebase", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/react-hooks/useFirebasePDP.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();